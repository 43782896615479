<div class="row">
  <div class="col-lg-12">
    <div class="custom-card">
      <div class="custom-card-body">
        <h5 class="custom-card-title">REFERRALS
        </h5>

      </div>
    </div>
  </div>
</div>

<div class="alert alert-dark" *ngIf="referralData == null">No referrals available</div>

<div class="row" *ngIf="referralData != null">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="active-member">
          <div class="table-responsive">
            <table class="table table-xs mb-0">
              <thead>
              <tr>
                <th>Id</th>
                <th>Minimum Amount</th>
                <th>New Customer</th>
                <th>Existing Customer</th>
                <th>is active</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{referralData.id}}</td>
                <td>{{referralData.minimum_amount}}</td>
                <td>{{referralData.new_customer}}</td>
                <td>{{referralData.existing_customer}}</td>
                <td>{{referralData.is_active}}</td>
                <td>
                  <button type="button" class="btn btn-xs btn-secondary" title="Edit" (click)="openModal(editReferral);editReferralData(referralData)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<pagination-controls *ngIf="referralData.length > 0" (pageChange)="pageChange($event)"></pagination-controls>-->


<ng-template #editReferral>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update Referral</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="basic-form">
          <form [formGroup]="editReferralDataForm">
            <div class="form-group">
              <label>Name</label>
              <input type="number"  formControlName="minimum_amount" class="form-control input-default" placeholder="Enter minimum amount">
            </div>
            <div class="form-group">
              <label>Name</label>
              <input type="number"  formControlName="new_customer" class="form-control input-default" placeholder="Enter new customer">
            </div>
            <div class="form-group">
              <label>Name</label>
              <input type="number"  formControlName="existing_customer" class="form-control input-default" placeholder="Enter existing customer">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="updateReferrals()" [disabled]="!editReferralDataForm.valid" [ngClass]="editReferralDataForm.valid? '' : 'btn-disabled'">Update Referral</button>
  </div>
</ng-template>
