
    <div class="card">
      <div class="card-body">
        <div class=" d-flex justify-content-between">
          <div>
            <h5 class="card-title">EXPERTS  </h5>
          </div>
          <div>

            <button class="btn btn-primary" (click)="showFilters=true">Filters</button>
          </div>
        </div>
        <ng-container *ngIf="showFilters">
            <div class="row" >
              <div class="col-md-4">
                <label>Expert Status</label>
                <select id="status" name="status" class="form-control input-default" [(ngModel)]="filters['status']">
                  <option value="" >All</option>
                  <option value="new">New</option>
                  <option value="accepted">Accepted</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
              <div class="col-md-4">
                <label>Expert Mobile No.</label>
                <input id="mobile_no" name="mobile_no" class="form-control input-default" [(ngModel)]="filters['mobile_no']"/>
              </div>
              <div class="col-md-4">
                <label>Expert Name</label>
                <input id="name" name="name" class="form-control input-default" [(ngModel)]="filters['name']"/>
              </div>

            </div>
            <div class="row d-flex justify-content-end">
              <button class="btn btn-primary m-2" (click)="showFilters=false" >Hide</button>
              <button class="btn btn-primary m-2" (click)="applyFilters()">Apply</button>
              <button class="btn btn-primary m-2" (click)="clearFilters()">Clear Filter</button>
            </div>
          </ng-container>
        </div>


    </div>


<div class="cbm10"></div>
<div class="alert alert-dark" *ngIf="expertsData.length == 0">No experts available</div>

<div class="row" *ngIf="expertsData.length > 0">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="active-member">
          <div class="table-responsive">
            <table class="table table-xs mb-0">
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Marital Status</th>
                <th>City</th>
                <th>Available Balance</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let expert of expertsData ">
                <td>{{expert.name | titlecase}}

                  <ng-container *ngIf="expert.is_verified">
                    <span class="badge badge-success">Authorized</span>
                  </ng-container>
                  <ng-container *ngIf="expert.is_verified==false">
                    <span class="badge badge-danger">Not Authorized</span>
                  </ng-container>
                  <ng-container *ngIf="expert.is_verified==null">
                    <span class="badge badge-info">New Registration</span>
                  </ng-container>

                </td>
                <td>{{expert.email |titlecase}}</td>
                <td>{{expert.mobile_number}}</td>
                <td>{{expert.marital_status |titlecase}}</td>
                <td>{{expert.city_id!=null?expert?.city_name?.name:expert.city}}</td>
                <td *ngIf="expert?.wallet != null">{{expert?.wallet}} <i class="fa fa-inr"></i></td>
                <td *ngIf="expert?.wallet == null">NA</td>
                <td>
                  <a type="button" class="btn btn-xs btn-primary" title="More Details" [routerLink]="['/admin','experts-details',expert.id]"><i class="fa fa-eye" aria-hidden="true"></i> Details</a>
                  <span class="midle"></span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<pagination [itemsPerPage]="itemsPerPage"  (pageChanged)="pageChange($event)" [totalItems]="totalCount"></pagination>


<ng-template #addExperts>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Experts</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="basic-form">
          <form [formGroup]="addExpertsForm">
            <div class="form-group">
              <label>Select Category</label>
              <ng-select2 [data]="categoryDataForExpert" [options]="options" placeholder="Please select category" formControlName="category_id" class="form-control input-default"></ng-select2>
            </div>
            <div class="form-group">
              <label>Name</label>
              <input type="text"  formControlName="name" class="form-control input-default" placeholder="Enter Expert Name">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="addExpert()">Add</button>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Expert Action</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <h4>Take action on expert.</h4>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="expertAllowRejectAction('allow')">Allow</button>
    <button type="button" class="btn btn-warning" (click)="expertAllowRejectAction('reject')">Reject</button>
  </div>
</ng-template>


<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg" *ngIf="expertDetailsData != null">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">Experts Details</h4>
        <button type="button" class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="basic-form">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Name</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.name" disabled>
                </div>
                <div class="form-group col-md-6">
                  <label>Email</label>
                  <input type="email" class="form-control" [value]="expertDetailsData.email" disabled>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Mobile Number</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.mobile_number" disabled>
                </div>
                <div class="form-group col-md-6">
                  <label>Marital Status</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.marital_status" disabled>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Education</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.education" disabled>
                </div>
                <div class="form-group col-md-4">
                  <label>City</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.city" disabled>
                </div>
                <div class="form-group col-md-4">
                  <label>Experience</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.experience" disabled>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Short Bio</label>
                  <textarea class="form-control" [value]="expertDetailsData.short_bio" disabled></textarea>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Available for video</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.available_for_video" disabled>
                </div>
                <div class="form-group col-md-6">
                  <label>Available for chat</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.available_for_chat" disabled>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-3">
                  <label>Email Verified At</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.email_verified_at" disabled>
                </div>
                <div class="form-group col-md-3">
                  <label>V Charge</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.v_charge" disabled>
                </div>
                <div class="form-group col-md-3">
                  <label>A Charge</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.a_charge" disabled>
                </div>
                <div class="form-group col-md-3">
                  <label>T Charge</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.t_charge" disabled>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-3">
                  <label>is verified</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.is_verified" disabled>
                </div>
                <div class="form-group col-md-3">
                  <label>Registration Payment</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.is_registration_payment_done" disabled>
                </div>
                <div class="form-group col-md-3">
                  <label>Rating</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.rating" disabled>
                </div>
                <div class="form-group col-md-3">
                  <label>Wallet</label>
                  <input type="text" class="form-control" [value]="expertDetailsData.wallet" disabled>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="lgModal.hide()">Close</button>
      </div>
    </div>
  </div>
</div>


<ng-template #assignExpertsRole>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Assign Role to {{expertDetailsData.name}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="basic-form">
          <form [formGroup]="assignRole">
            <div class="form-group">
              <label>Select Role</label>
              <ng-select2 [data]="rolesOptionList" [options]="options" placeholder="Please select role" formControlName="role" class="form-control input-default"></ng-select2>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="assignRoleToExpert()" [disabled]="!assignRole.valid" [ngClass]="assignRole.valid? '' : 'btn-disabled'">Assign Role</button>
  </div>
</ng-template>

