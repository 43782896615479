<div class="row">
  <div class="col-lg-12">
    <div class="custom-card">
      <div class="custom-card-body">
        <h5 class="custom-card-title">ROLES
          <!-- Button trigger modal -->
          <button type="button" class="btn btn-sm btn-primary" title="Add Role" style="float: right;" (click)="openModal(addRole)"><i class="fa fa-plus" aria-hidden="true"></i></button>
        </h5>

      </div>
    </div>
  </div>
</div>

<div class="alert alert-dark" *ngIf="rolesData.length == 0">No role available</div>

<div class="row" *ngIf="rolesData.length > 0">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="active-member">
          <div class="table-responsive">
            <table class="table table-xs mb-0">
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let role of rolesData | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage}">
                <td>{{role.id}}</td>
                <td>{{role.name}}</td>
                <td><button type="button" class="btn btn-xs btn-secondary" title="Edit" (click)="openModal(editRole);editRoles(role)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<pagination-controls *ngIf="rolesData.length > 0" (pageChange)="pageChange($event)"></pagination-controls>

<ng-template #addRole>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Role</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="basic-form">
          <form [formGroup]="addRoleForm">
            <div class="form-group">
              <label>Name</label>
              <input type="text"  formControlName="name" class="form-control input-default" placeholder="Enter Role Name">
            </div>
            <div class="form-group">
              <label>Choose Role</label>
              <br>

              <div class="form-check" *ngFor="let permission of permissionData; let i = index" style="margin: 5px 10px 5px 0px;">
                <input class="form-check-input" type="checkbox" value="{{permission.id}}" [checked]="permission.checked" (click)="selectedPermission(permission, i)">
                <label class="form-check-label">
                  {{permission.name}}
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="addRoles()" [disabled]="!addRoleForm.valid" [ngClass]="addRoleForm.valid? '' : 'btn-disabled'">Add</button>
  </div>
</ng-template>


<ng-template #editRole>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Role</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="basic-form">
          <form [formGroup]="editRoleForm">
            <div class="form-group">
              <label>Name</label>
              <input type="text"  formControlName="name" class="form-control input-default" placeholder="Enter Role Name">
            </div>
            <div class="form-group">
              <label>Choose Role</label>
              <br>

              <div class="form-check" *ngFor="let permission of permissionData; let i = index" style="margin: 5px 10px 5px 0px;">
                <input class="form-check-input" type="checkbox" value="{{permission.id}}" [checked]="permission.checked" (click)="selectedPermission(permission, i)">
                <label class="form-check-label">
                  {{permission.name}}
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="updateRoles()" [disabled]="!editRoleForm.valid" [ngClass]="editRoleForm.valid? '' : 'btn-disabled'">Add</button>
  </div>
</ng-template>
