<div class="card">
  <div class="card-body">
    <div class=" d-flex justify-content-between">
      <div>
        <h5 class="card-title">Live Sessions  </h5>
      </div>
      <div>

        <button class="btn btn-primary" (click)="showFilters=true">Filters</button>
      </div>
    </div>
    <ng-container *ngIf="showFilters">
      <div class="row" >
        <div class="col-md-4">
          <label>Expert Status</label>
          <select id="status" name="status" class="form-control input-default" [(ngModel)]="filters['status']">
            <option value="" >All</option>
            <option value="Pending">New</option>
            <option value="Accepted">Accepted</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
      </div>
      <div class="row d-flex justify-content-end">
        <button class="btn btn-primary m-2" (click)="showFilters=false" >Hide</button>
        <button class="btn btn-primary m-2" (click)="applyFilters()">Apply</button>
        <button class="btn btn-primary m-2" (click)="clearFilters()">Clear Filter</button>
      </div>
    </ng-container>
  </div>


</div>

<div class="alert alert-dark" *ngIf="liveSessionData.length == 0">No live sessions available</div>

<div class="row" *ngIf="liveSessionData.length > 0">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="active-member">
          <div class="table-responsive">
            <table class="table table-xs mb-0">
              <thead>
              <tr>
                <th>Session Name</th>
                <th>Expert Name</th>
                <th>Starting</th>
                <th>Duration (mins) </th>
                <th>Session Charges</th>
                <th>Is allowed</th>
                <th>Description</th>
                <th>Banner Image</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let session of liveSessionData ">
                <td>{{session.name | titlecase}}</td>
                <td><a [routerLink]="['/admin','experts-details',session.expert_details?.id]">{{session.expert_details?.name | titlecase}}</a></td>
                <td>{{session.starting}}</td>
                <td>{{session.duration}}</td>
                <td>{{session.price}}</td>
                <td>
                  <ng-container *ngIf="session.is_allowed">
                    <span class="badge badge-success">Allowed</span>
                  </ng-container>
                  <ng-container *ngIf="session.is_allowed==false">
                    <span class="badge badge-danger">Not Allowed</span>
                  </ng-container>
                  <ng-container *ngIf="session.is_allowed==null">
                    <span class="badge badge-info">New Request</span>
                  </ng-container>
                </td>
                <td>{{session.description | titlecase}}</td>
                <td><img style="max-height: 250px; width: 170px" [src]="session.image"/> </td>
                <td>
                  <button (click)="openDetailsModal(session)" type="button" class="btn btn-xs btn-info" title="Action"><i class="fa fa-tasks" aria-hidden="true"></i> Action</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<pagination [itemsPerPage]="itemsPerPage"  (pageChanged)="pageChange($event)" [totalItems]="totalCount"></pagination>



<ng-template #liveSessionDetails>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Expert Action</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <h4>Take action on expert request.</h4>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="expertAllowRejectAction(1)">Allow</button>
    <button type="button" class="btn btn-warning" (click)="expertAllowRejectAction(0)">Reject</button>
  </div>
</ng-template>
