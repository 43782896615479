<div class="row">
  <div class="col-lg-12">
    <div class="custom-card">
      <div class="custom-card-body">
        <h5 class="custom-card-title">Carousal</h5>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-dark" *ngIf="reviewsData.length == 0">No reviews available</div>

<div class="row" *ngIf="reviewsData.length > 0">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="active-member">
          <div class="table-responsive">
            <table class="table table-xs mb-0">
              <thead>
              <tr>
                <th>Image</th>
                <th>Order</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody >
              <tr *ngFor="let review of reviewsData ">

                <td><img style="width: 310px;" src="{{review?.image_path}}" width="300"></td>
                <td>{{review?.order_no}}</td>
                <td>
<!--                  <button type="button" class="btn btn-xs btn-success" title="Accept" (click)="actionOnReview(review, 'accept')"><i class="fa fa-check" aria-hidden="true"></i></button>-->
<!--                  <span class="midle"></span>-->
<!--                  <button type="button" class="btn btn-xs btn-danger" title="Reject" (click)="actionOnReview(review, 'reject')"><i class="fa fa-times" aria-hidden="true"></i></button>-->
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<pagination [itemsPerPage]="itemsPerPage"  (pageChanged)="pageChange($event)" [totalItems]="totalItems"></pagination>

