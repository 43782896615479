<!--*******************
        Preloader start
    ********************-->
<!--<div id="preloader">-->
<!--  <div class="loader">-->
<!--    <svg class="circular" viewBox="25 25 50 50">-->
<!--      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10" />-->
<!--    </svg>-->
<!--  </div>-->
<!--</div>-->
<!--*******************
    Preloader end
********************-->


<!--**********************************
    Main wrapper start
***********************************-->
<div id="main-wrapper show">

  <!--**********************************
      Nav header start
  ***********************************-->
  <div class="nav-header">
    <div class="brand-logo text-center">
      <img src="assets/images/appIcon.png" width="40%" alt="">

      <a href="index.html">
        <span class="brand-title">
            <h3 class="text-center text-white">Lyffix</h3>
        </span>
      </a>
    </div>
  </div>
  <!--**********************************
      Nav header end
  ***********************************-->

  <!--**********************************
      Header start
  ***********************************-->
  <div class="header">
    <div class="header-content clearfix">

      <div class="nav-control">
        <div class="hamburger">
          <span class="toggle-icon"><i class="icon-menu"></i></span>
        </div>
      </div>
      <div class="header-left">
        <div class="input-group icons">
          <div class="input-group-prepend">
            <span class="input-group-text bg-transparent border-0 pr-2 pr-sm-3" id="basic-addon1"><i class="mdi mdi-magnify"></i></span>
          </div>
          <input type="search" class="form-control" placeholder="Search Dashboard" aria-label="Search Dashboard">
          <div class="drop-down animated flipInX d-md-none">
            <form action="#">
              <input type="text" class="form-control" placeholder="Search">
            </form>
          </div>
        </div>
      </div>
      <div class="header-right">
        <ul class="clearfix">

          <li class="icons dropdown">
            <div class="user-img c-pointer position-relative"   data-toggle="dropdown">
              <span class="activity active"></span>
              <img src="assets/images/user/1.png" height="40" width="40" alt="">
            </div>
            <div class="drop-down dropdown-profile animated fadeIn dropdown-menu">
              <div class="dropdown-content-body">
                <ul>

                  <li (click)="logout()"><a href=""><i class="icon-key"></i> <span>Logout</span></a></li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--**********************************
      Header end ti-comment-alt
  ***********************************-->

  <!--**********************************
      Sidebar start
  ***********************************-->
  <div class="nk-sidebar" >
    <div class="nk-nav-scroll">
      <ul class="metismenu" id="menu" metis-menu>
        <li>
          <a href="#/dashboard">
            <i class="icon-speedometer menu-icon"></i><span class="nav-text">Dashboard</span>
          </a>
        </li>

        <li class="nav-label">
          Metadata
        </li>

        <li class="mega-menu mega-menu-sm">
          <a class="has-arrow" href="javascript:void()" aria-expanded="false">
            <i class="fa fa-language" aria-hidden="true"></i><span class="nav-text">Languages</span>
          </a>
          <ul aria-expanded="false">
            <li><a [routerLink]="['/admin','language']">View</a></li>
            <li><a [routerLink]="['/admin','trash-languages']">Trash</a></li>
          </ul>
        </li>

        <li class="mega-menu mega-menu-sm">
          <a class="has-arrow" href="javascript:void()" aria-expanded="false">
            <i class="fa fa-bars" aria-hidden="true"></i><span class="nav-text">Category</span>
          </a>
          <ul aria-expanded="false">
            <li><a [routerLink]="['/admin','category']" >Main Category</a></li>
            <li><a [routerLink]="['/admin','sub-category']" >Sub Category</a></li>
            <li><a [routerLink]="['/admin','trash-categories']">Trash</a></li>
          </ul>
        </li>

        <li class="mega-menu mega-menu-sm">
          <a class="has-arrow" href="javascript:void()" aria-expanded="false">
            <i class="fa fa-map-o" aria-hidden="true"></i><span class="nav-text">City</span>
          </a>
          <ul aria-expanded="false">
            <li><a [routerLink]="['/admin','city']" >View</a></li>
            <li><a [routerLink]="['/admin','trash-city']">Trash</a></li>
          </ul>
        </li>

        <li>
          <a [routerLink]="['/admin','experts']" >
            <i class="fa fa-check-circle-o"></i><span class="nav-text">Experts</span>
          </a>
        </li>

        <li>
          <a [routerLink]="['/admin','customer-reviews']" >
            <i class="fa fa-commenting-o"></i><span class="nav-text">Customer Reviews</span>
          </a>
        </li>

        <li>
          <a [routerLink]="['/admin','live-session']" >
            <i class="fa fa-television" aria-hidden="true"></i><span class="nav-text">Live-sessions</span>
          </a>
        </li>

        <li>
          <a [routerLink]="['/admin','offers']" >
            <i class="fa fa-money" aria-hidden="true"></i><span class="nav-text">Offers</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/admin','carousal']" >
            <i class="fa fa-money" aria-hidden="true"></i><span class="nav-text">Carousal</span>
          </a>
        </li>

        <li class="mega-menu mega-menu-sm">
          <a class="has-arrow" href="javascript:void()" aria-expanded="false">
            <i class="fa fa-user-circle-o" aria-hidden="true"></i><span class="nav-text">Users</span>
          </a>
          <ul aria-expanded="false">
            <li><a [routerLink]="['/admin','admin-user']" >Admin</a></li>
            <li><a [routerLink]="['/admin','customer']" >Customer</a></li>
          </ul>
        </li>

        <li class="mega-menu mega-menu-sm">
          <a class="has-arrow" href="javascript:void()" aria-expanded="false">
            <i class="fa fa-users" aria-hidden="true"></i><span class="nav-text">Roles & Permissions</span>
          </a>
          <ul aria-expanded="false">
            <li><a [routerLink]="['/admin','roles']" >Roles</a></li>
            <li><a [routerLink]="['/admin','permissions']" >Permissions</a></li>
          </ul>
        </li>

        <li>
          <a  [routerLink]="['/admin','referrals']" >
            <i  class="fa fa-share-alt" aria-hidden="true"></i><span class="nav-text">Referrals</span>
          </a>
        </li>

      </ul>
    </div>
  </div>
  <!--**********************************
      Sidebar end
  ***********************************-->

  <!--**********************************
      Content body start
  ***********************************-->
  <div class="content-body">
    <div style="margin: 10px">
      <router-outlet></router-outlet>
    </div>
    <!-- #/ container -->
  </div>
  <!--**********************************
      Content body end
  ***********************************-->


  <!--**********************************
      Footer start
  ***********************************-->
  <div class="footer">
    <div class="copyright">
      <p>Copyright &copy; Designed & Developed by <a href="https://themeforest.net/user/quixlab">Quixlab</a> 2018</p>
    </div>
  </div>
  <!--**********************************
      Footer end
  ***********************************-->
</div>
<!--**********************************
    Main wrapper end
***********************************-->
