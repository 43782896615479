<div class="card">
  <div class="card-title">

  </div>
  <div class="card-body">
    <ng-container *ngIf="expertDetails!=null">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Default Tab</h4>
              <!-- Nav tabs -->
              <div class="default-tab">
                <ul class="nav nav-tabs mb-3" role="tablist">
                  <li class="nav-item"><a class="nav-link active show" data-toggle="tab" href="#home">Profile</a>
                  </li>
                  <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#profile">Call Log Details</a>
                  </li>
                  <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#contact">Money Withdraw/Transfer</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fade active show" id="home" role="tabpanel">
                    <div class="p-t-15">
                      <div class="row">
                        <div class="col-12 d-flex justify-content-between">
                          <div>
                            <ng-container *ngIf="expertDetails.is_verified">
                              <h4 class="">User Status : <span class="bg-success p-1">Authorized</span></h4>
                            </ng-container>
                            <ng-container *ngIf="expertDetails.is_verified==false">
                              <h4 class="">User Status : <span class="bg-danger p-1">Not Authorized</span></h4>
                            </ng-container>
                            <ng-container *ngIf="expertDetails.is_verified==null">
                              <h4 class="">User Status : <span class="bg-info p-1">New Registration</span></h4>
                            </ng-container>
                          </div>
                          <div>
                          <button class="btn btn-primary" (click)="openModal(userChangeStatus)">Change Status</button>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                              <tr>
                                <td class="table-key-name">Expert Name</td>
                                <td>{{expertDetails?.name}}</td>
                                <td class="table-key-name" >Expert Mobile</td>
                                <td>{{expertDetails?.mobile_number}}</td>
                              </tr>
                              <tr>
                                <td class="table-key-name">Expert Email</td>
                                <td>{{expertDetails?.email}}</td>
                                <td class="table-key-name" >Expert City</td>
                                <td>{{expertDetails.city_id!=null?expertDetails?.city_name?.name:expertDetails.city}}</td>
                              </tr>
                              <tr>
                                <td class="table-key-name">Expert About</td>
                                <td>{{expertDetails?.short_bio}}</td>
                                <td class="table-key-name" >Expert Education</td>
                                <td>{{expertDetails?.education}}</td>
                              </tr>
                              <tr>
                                <td class="table-key-name">Expert Categories</td>
                                <td>
                                  <ng-container *ngFor="let category of expertDetails.category">{{category['name']+' ,' }}</ng-container>
                                </td>
                                <td class="table-key-name">Expert Level</td>
                                <td>{{expertDetails?.level?.name}}</td>
                              </tr>
                              <tr>
                                <td class="table-key-name">Expert Experience</td>
                                <td>{{expertDetails?.experience}}</td>
                                <td class="table-key-name" >Expert Experties</td>
                                <td>{{expertDetails?.expertise}}</td>
                              </tr>
                              <tr>
                                <td class="table-key-name">Profile Photo</td>
                                <td><img style="max-width: 300px" [src]="expertDetails?.profile_link"></td>
                                <td class="table-key-name" >Expert Certificates</td>
                                <td>
                                  <span *ngIf="expertDetails?.certificate1"><img style="max-width: 300px" [src]="expertDetails?.certificate1"><br></span>
                                  <span *ngIf="expertDetails?.certificate2"><img style="max-width: 300px" [src]="expertDetails?.certificate2"><br></span>
                                  <span *ngIf="expertDetails?.certificate3"><img style="max-width: 300px" [src]="expertDetails?.certificate3"><br></span>
                                </td>
                              </tr>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade " id="profile">
                    <div class="p-t-15">
                      <div class="alert alert-dark" *ngIf="walletLogData.length == 0">No logs available</div>

                      <div class="row" *ngIf="walletLogData.length > 0">
                        <div class="col-lg-12">
                          <div class="card">
                            <div class="card-body">
                              <div class="active-member">
                                <div class="table-responsive">
                                  <table class="table table-xs mb-0">
                                    <thead>
                                    <tr>
                                      <th>Description</th>
                                      <th>Amount</th>
                                      <th>Type</th>
                                      <th>Balance</th>
                                      <th>Duration</th>
                                      <th>Transaction Type</th>
<!--                                      <th>Action</th>-->
                                    </tr>
                                    </thead>
                                    <tbody >
                                    <tr *ngFor="let log of walletLogData ">

                                      <td>{{log?.description}}</td>
                                      <td style="color:{{log.type=='Credit'?'green':'red'}}"> {{log.type=="Credit"?log.credit:log.debit}}</td>
                                      <td>{{log?.type}}</td>
                                      <td>{{log?.balance}}</td>
                                      <td>{{log?.duration}}</td>
                                      <td>{{log?.callType}}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <pagination [itemsPerPage]="itemsWalletLogDataPerPage"  (pageChanged)="walletDataPageChange($event)" [totalItems]="totalWalletLogDataItems"></pagination>
                    </div>
                  </div>
                  <div class="tab-pane fade " id="contact">
                    <div class="p-t-15">
                      <h4>Money Withdraw/Transfer To Expert</h4>
                      <p>Enter Amount to transfer to expert</p>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="card">
                            <div class="card-body">
                              <span>Wallet Balance</span>
                              {{walletBalance}}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-12">
                                  <input class="form-control" [(ngModel)]="amountToTransfer" [min]="1" >
                                </div>
                                <div class="col-12">
                                  <button *ngIf="walletBalance>0" (click)="withdraw()" class="btn btn-outline-primary my-2">
                                    Withdraw Amount
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="message">
                    <div class="p-t-15">
                      <h4>Comming Soon</h4>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>


<ng-template #userChangeStatus>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Expert Action</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <h4>Take action on expert.</h4>
        <label>Comment</label>
        <input class="input-group form-control" [(ngModel)]="reason">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="expertAllowRejectAction(1)">Allow</button>
    <button type="button" class="btn btn-warning" (click)="expertAllowRejectAction(0)">Reject</button>
  </div>
</ng-template>
