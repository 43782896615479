<!--**********************************
       Footer start
   ***********************************-->
<div class="footer">
  <div class="copyright">
    <p>Copyright &copy; Designed & Developed by <a href="https://themeforest.net/user/quixlab">Quixlab</a> 2018</p>
  </div>
</div>
<!--**********************************
    Footer end
***********************************-->
