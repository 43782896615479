m  <div class="row">
  <div class="col-lg-12">
    <div class="custom-card">
      <div class="custom-card-body">
        <h5 class="custom-card-title">ADMIN
          <!-- Button trigger modal -->
          <button type="button" class="btn btn-sm btn-primary" title="Add Admin" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
        </h5>

      </div>
    </div>
  </div>
</div>

<div class="alert alert-dark" *ngIf="adminUsersData.length == 0">No Admin available</div>

<div class="row" *ngIf="adminUsersData.length > 0">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="active-member">
          <div class="table-responsive">
            <table class="table table-xs mb-0">
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let admin of adminUsersData | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage}">
                <td>{{admin.id}}</td>
                <td>{{admin.name}}</td>
                <td>{{admin.email}}</td>
                <td>{{admin.mobile_number}}</td>
                <td><button type="button" class="btn btn-xs btn-secondary" title="Assign Role" (click)="openModal(assignAdminRole);adminDetails(admin)"><i class="fa fa-random" aria-hidden="true"></i></button></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<pagination-controls *ngIf="adminUsersData.length > 0" (pageChange)="pageChange($event)"></pagination-controls>

<ng-template #assignAdminRole>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Assign Role to {{adminDetailsData.name}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="basic-form">
          <form [formGroup]="assignRole">
            <div class="form-group">
              <label>Select Role</label>
              <ng-select2 [data]="rolesOptionList" [options]="options" placeholder="Please select role" formControlName="role" class="form-control input-default"></ng-select2>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="assignRoleToAdmin()" [disabled]="!assignRole.valid" [ngClass]="assignRole.valid? '' : 'btn-disabled'">Assign Role</button>
  </div>
</ng-template>

