<div class="login-form-bg h-100">
  <div class="container h-100">
    <div class="row justify-content-center h-100">
      <div class="col-xl-6">
        <div class="form-input-content">
          <div class="card login-form mb-0">
            <div class="card-body pt-5 text-center">
              <img class="text-center" src="assets/images/appIcon.png" width="40%" alt="">

              <a class="text-center" href="../../../index.html"> <h4 class="mt-4">Sign In</h4></a>
              <form class="mt-5 mb-5 login-input" [formGroup]="userLogin">
                <div class="form-group">
                  <input type="email" name="email" formControlName="email" class="form-control" placeholder="Email">
                </div>
                <div class="form-group">
                  <input type="password" name="password" formControlName="password" class="form-control" placeholder="Password">
                </div>
                <button class="btn login-form__btn submit w-100" (click)="userLogIn()" [disabled]="!userLogin.valid" [ngClass]="userLogin.valid? '' : 'btn-disableds'">Sign In</button>
              </form>
              <p class="mt-5 login-form__footer">Don't have account? <a href="register" class="text-primary">Sign Up</a> now</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
