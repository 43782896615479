<div class="row">
  <div class="col-lg-12">
    <div class="custom-card">
      <div class="custom-card-body">
        <h5 class="custom-card-title">METADATA
          <!-- Button trigger modal -->
          <button type="button" class="btn btn-sm btn-primary backdrop-static" title="Add Metadata" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
        </h5>

      </div>
    </div>
  </div>
</div>
