<div class="row">
  <div class="col-lg-12">
    <div class="custom-card">
      <div class="custom-card-body">
        <h5 class="custom-card-title">CITY
          <!-- Button trigger modal -->
          <button type="button" class="btn btn-sm btn-primary backdrop-static" title="Add City" style="float: right;" (click)="openModal(template)"><i class="fa fa-plus" aria-hidden="true"></i></button>
        </h5>

      </div>
    </div>
  </div>
</div>

<div class="alert alert-dark" *ngIf="cityData.length == 0">No city(s) available</div>

<div class="row" *ngIf="cityData.length > 0">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="active-member">
          <div class="table-responsive">
            <table class="table table-xs mb-0">
              <thead>
              <tr>
                <th>City Name</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let city of cityData ">
                <td>{{city.name}}</td>
                <td>
                  <button type="button" class="btn btn-xs btn-secondary" title="Edit" (click)="openModal(template2);editLanguage(city)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                  <span class="midle"></span>
                  <button type="button" class="btn btn-xs btn-danger" title="Delete" (click)="deleteCity(city)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<pagination [itemsPerPage]="itemsPerPage"  (pageChanged)="pageChange($event)" [totalItems]="totalCount"></pagination>


<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add City</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="basic-form">
          <form [formGroup]="addCityForm">
            <div class="form-group">
              <label>Name</label>
              <input type="text"  formControlName="name" class="form-control input-default" placeholder="Enter City Name">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="addCity()">Add</button>
  </div>
</ng-template>

<ng-template #template2>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update City</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="basic-form">
          <form [formGroup]="editCityForm">
            <div class="form-group">
              <label>Name</label>
              <input type="text"  formControlName="name" class="form-control input-default" placeholder="Enter City Name">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="updateCity()">Save</button>
  </div>
</ng-template>
